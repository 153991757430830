import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";

import SubCategory from "../components/Products/Pages/SubCategoryPage";
import PopularProducts from "../components/Products/Components/PopularProducts";

class Page extends Component {
  render() {
    const products = this.props.data.allWpProduct.nodes;
    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: this.props.data.wpProductTag.uri,
        }}
        path={"a"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <SubCategory
          {...this.props.data.wpProductTag}
          products={products}
          total={products.length}
          name={this.props.data.wpProductTag.name}
        />
        <PopularProducts
          products={
            this.props.data.wp.themeOptions.ThemeOptions.popularProducts
          }
          limit={4}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String) {
    wpProductTag(id: { eq: $id }) {
      name
      uri
    }
    allWpProduct(
      filter: { productTags: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      nodes {
        id
        slug
        title
        link
        featuredImage {
          node {
            sourceUrl
          }
        }
        product {
          code
          description
          introduction
          order
          overview
          shortDescription
          variations {
            variantCode
            variantName
          }
          productGallery {
            id
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1660) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        brands {
          nodes {
            BrandExtras {
              logo {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        productCategories {
          nodes {
            name
            id
          }
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          popularProducts {
            ... on WpProduct {
              id
              title
              uri
              uri
              link
              featuredImage {
                node {
                  sourceUrl
                }
              }
              product {
                code
                description
                fieldGroupName
                fixDescription
                fixGuide
                fixVideo
                hasHowtoGuide
                installDescription
                installGuide
                installVideo
                introduction
                overview
                shortDescription
              }
              brands {
                nodes {
                  BrandExtras {
                    logo {
                      localFile {
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 1660) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
